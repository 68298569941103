<template>
  <div class="container-fluid main">
    <div class="row">
     <div class="kolumna">
        <div class="blok">
          <h3>Clear</h3>
          <!-- Backlog draggable component. Pass arrBackLog to list prop -->
          <draggable
            class="lista kanban-column"
            :list="arrclear"
            group="tasks"
          >
            <div
              class="lista-item"
              v-for="element in arrclear"
              :key="element.name"
              :style="'border:1px solid '+element.color"
               @dblclick="namechange(element.id,'arrclear')"           >
               <img class="crewmate" :src="element.img">
              <span :id="element.id+'arrclear'+'span'" class="enable"  >{{ element.name }}</span>
              <input :id="element.id+'arrclear'+'input'" @focusout="savename(element.id,'arrclear')" class="disable inputname" type="text" v-model="value">
            
            </div>
          </draggable>
        </div>
      </div>
</div>

       <div class="row ">
           <div class="kolumna">
        <div class="blok">
          <h3>Soft Clear</h3>
          <!-- In Progress draggable component. Pass arrInProgress to list prop -->
          <draggable
            class="lista kanban-column"
            :list="arrsoftclear"
            group="tasks"
          >
            <div
              class="lista-item"
              v-for="element in arrsoftclear"
              :key="element.name"
              :style="'border:1px solid '+element.color"
               @dblclick="namechange(element.id,'arrsoftclear')"           >
               <img class="crewmate" :src="element.img">
              <span :id="element.id+'arrsoftclear'+'span'" class="enable"  >{{ element.name }}</span>
              <input :id="element.id+'arrsoftclear'+'input'" @focusout="savename(element.id,'arrsoftclear')" class="disable inputname" type="text" v-model="value">
              
            </div>
          </draggable>
        </div>
     
 </div>    
 </div>

       <div class="row ">
      <div class="kolumna">
        <div class="blok">
          <h3>Impostor</h3>
          <!-- Testing draggable component. Pass arrTested to list prop -->
          <draggable
            class="lista kanban-column"
            :list="arrinpostor"
            group="tasks"
          >
            <div
              class="lista-item"
              v-for="element in arrinpostor"
              :key="element.name"
              :style="'border:1px solid '+element.color"
               @dblclick="namechange(element.id,'arrinpostor')"           >
               <img class="crewmate" :src="element.img">
              <span :id="element.id+'arrinpostor'+'span'" class="enable"  >{{ element.name }}</span>
              <input :id="element.id+'arrinpostor'+'input'" @focusout="savename(element.id,'arrinpostor')" class="disable inputname" type="text" v-model="value">
             
            </div>
          </draggable>
        </div>
      </div>
 </div>

       <div class="row">
      <div class="kolumna">
        <div class="blok">
          <h3>Sus</h3>
          <!-- Done draggable component. Pass arrDone to list prop -->
          <draggable
            class="lista kanban-column"
            :list="arrsus"
            group="tasks"
          >
            <div
              class="lista-item"
              v-for="element in arrsus"
              :key="element.name"
              :style="'border:1px solid '+element.color"
               @dblclick="namechange(element.id,'arrsus')"           >
               <img class="crewmate" :src="element.img">
              <span :id="element.id+'arrsus'+'span'" class="enable"  >{{ element.name }}</span>
              <input :id="element.id+'arrsus'+'input'" @focusout="savename(element.id,'arrsus')" class="disable inputname" type="text" v-model="value">
            
            </div>
          </draggable>
        </div>
     </div>
        </div>

       <div class="row">
            <div class="kolumna">
        <div class="blok">
          <h3>Players</h3>
          <!-- Done draggable component. Pass arrDone to list prop -->
          <draggable
            class="lista kanban-column"
            :list="arrplayer"
            group="tasks"
          >
            <div
              class="lista-item"
              v-for="element in arrplayer"
              :key="element.name"
              :style="'border:1px solid '+element.color"
               @dblclick="namechange(element.id,'arrplayer')"           >
                             <img class="crewmate" :src="element.img">
              <span :id="element.id+'arrplayer'+'span'" class="enable"  >{{ element.name }}</span>
              <input :id="element.id+'arrplayer'+'input'" @focusout="savename(element.id,'arrplayer')" class="disable inputname" type="text" v-model="value">
              
            </div>
          </draggable>
        </div>
       </div>
</div>
      
    
  </div>
</template>

<script>
//import draggable
import draggable from "vuedraggable";
export default {
  name: "kanban-board",
  components: {
    //import draggable as a component
    draggable
  },
  data() {
    return {
      value:"",
      arrclear: [],
      arrsoftclear: [],
      arrsus: [],
      arrinpostor: [],
      arrplayer: [
        { id:1 ,name: "Czerwony",color:"#c51112",img:"/img/red.png" },//img/all.png
        { id:2 ,name: "Niebieski",color:"#142fd6",img:"/img/blue.png" },
        { id:3 ,name: "Zielony",color:"#147f2f",img:"/img/green.png" },
        { id:4 ,name: "Zółty",color:"#f9f556",img:"/img/yellow.png" },
        { id:5 ,name: "Pomarańczowy",color:"#f17b10",img:"/img/orange.png" },
        { id:6 ,name: "Czarny",color:"#41444d",img:"/img/black.png" },
        { id:7 ,name: "Biały",color:"#d8dff1",img:"/img/white.png" },
        { id:8 ,name: "Fioletowy",color:"#6f30bd",img:"/img/purple.png" },
        { id:9 ,name: "Cyjanowy",color:"#3afddf",img:"/img/cyan.png" },
        { id:10 ,name: "Brązowy",color:"#71451e",img:"/img/brown.png" },
        { id:11 ,name: "Limonkowy",color:"#51f139",img:"/img/lime.png" },
        { id:12 ,name: "Różowy",color:"#ee52b9",img:"/img/pink.png" }
        ],

    };
  },
  methods: {
    //add new tasks method
namechange(id,arrayname){
document.getElementById(id+arrayname+"input").className="enable inputname"
document.getElementById(id+arrayname+"span").className="disable"
},
savename(id,arrayname){
document.getElementById(id+arrayname+"input").className="disable inputname"
document.getElementById(id+arrayname+"span").className="enable"
var i=0;
if(arrayname=="arrplayer"){

  for(i = 0;this.arrplayer.length>i;i++){
    
    if(this.arrplayer[i].id==id){

    this.arrplayer[i].name=this.value;    }

  }
}
if(arrayname=="arrinpostor"){

  for(i = 0;this.arrinpostor.length>i;i++){
    
    if(this.arrinpostor[i].id==id){

    this.arrinpostor[i].name=this.value;    }

  }
}
if(arrayname=="arrsoftclear"){

  for(i = 0;this.arrsoftclear.length>i;i++){
    
    if(this.arrsoftclear[i].id==id){

    this.arrsoftclear[i].name=this.value;    }

  }
}
if(arrayname=="arrsus"){

  for(i = 0;this.arrsus.length>i;i++){
    
    if(this.arrsus[i].id==id){

    this.arrsus[i].name=this.value;    }

  }
}
if(arrayname=="arrclear"){

  for(i = 0;this.arrclear.length>i;i++){
    
    if(this.arrclear[i].id==id){

    this.arrclear[i].name=this.value;    }

  }
}

this.value=""
}
  }
};
</script>

<style>
/* light stylings for the kanban columns */
.kolumna{
    width: 300px;
}
.disable{
  display: none;
}
.enable{
  display:inline;
}
.kanban-column {
  min-height: 50px;
}
.blok{
    border: 1px solid black;
    margin:  1px;
    width: 300px;
}
.kolumna h3{
  border-bottom: 1px solid black;
}
.lista{
    width: 300px;

}
.inputname{
  width: 120px;
  height: 20px;
  box-sizing: border-box;
}
.lista-item{
    display: inline-block;
    width: 150px;
}
.crewmate{
  width: 20px;
}
</style>