<template>
  <div id="app">

    <Among/>
  </div>
</template>

<script>
import Among from './components/Among.vue'

export default {
  name: 'App',
  components: {
    Among
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color:rgb(221, 221, 221);
  margin-top: 60px;
}

</style>
